import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~7],
		"/app": [12,[3]],
		"/app/control-center": [~13,[3,4]],
		"/app/control-center/alerts": [14,[3,4]],
		"/app/control-center/lists": [15,[3,4]],
		"/app/control-center/lists/[listId]": [16,[3,4]],
		"/app/logout": [17,[3]],
		"/app/profile": [18,[3]],
		"/app/search": [19,[3]],
		"/app/topics": [20,[3,5]],
		"/app/topics/[topicId]": [21,[3,5]],
		"/auth/login": [22,[6]],
		"/auth/signup": [~23,[6]],
		"/auth/verify-code": [24,[6]],
		"/(legal)/california-resident-notice-at-collection": [8,[2]],
		"/(legal)/privacy-policies": [9,[2]],
		"/(legal)/refund-policy": [10,[2]],
		"/(legal)/terms-and-conditions": [11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';